import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Button from 'components/ui/Button';
import * as Styled from './styles';
// import downloadFile from '../../../assets/downloadables/market.pdf';

const TitleSection = ({ center, title, content, subtitle, linkTo, linkText }) => (
  <Styled.TitleSection>
    {subtitle && <Styled.SubTitle center={center}>{title}</Styled.SubTitle>}
    <Styled.Title center={center}>{subtitle}</Styled.Title>
    <Styled.Content>{content}</Styled.Content>
    <Styled.Link href={linkTo}>
      <Button primary>{linkText}</Button>
    </Styled.Link>
  </Styled.TitleSection>
);

TitleSection.propTypes = {
  center: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string,
  linkTo: PropTypes.string,
  linkText: PropTypes.string
};

export default TitleSection;
