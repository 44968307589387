import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import Container from 'components/ui/Container';
import TitleSectionHero from 'components/ui/TitleSectionHero';
import './background.css';
import * as Styled from './styles';

const Banner = ({ title, subtitle, content, linkTo, linkText }) => (
  <Styled.Banner id="backdrop">
    <Header />

    <Container section>
      <TitleSectionHero title={title} subtitle={subtitle} content={content} linkTo={linkTo} linkText={linkText} />
    </Container>
  </Styled.Banner>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.any.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default Banner;
