import styled from 'styled-components';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';

export const Services = styled.div`
  ${tw`flex flex-wrap -mx-3 `};
`;

export const ServiceItem = styled.div`
  ${tw`w-full sm:w-1/2 `};
`;

export const Button = motion.custom(styled.button`
  outline: none !important;
  ${tw`py-2 px-8 rounded-full border hover:border-yellow-600 border-purple-400 text-white justify-center`};

  ${({ primary }) => (primary ? tw`bg-purple-900 self-center` : tw`text-purple-600`)};

  ${({ block }) => block && tw`w-full`};
`);
