import styled from 'styled-components';
import tw from 'tailwind.macro';

export const TitleSection = styled.div`
  ${tw`flex flex-col w-full p-0`};
`;

export const Title = styled.h2`
  ${tw`mb-4 text-6xl text-white font-bold w-auto text-left`};
  ${({ center }) => center && tw`text-center`};
`;

export const SubTitle = styled.h4`
  ${tw`text-4xl font-weight-500 text-yellow-600 w-auto text-left p-0`};
  ${({ center }) => center && tw`text-center`};
`;

export const Link = styled.a`
  ${tw`py-8 justify-start`};
`;

export const Content = styled.p`
  ${tw`mb-8 text-white text-xl`};
`;
